<template>
	<div id="meetdetails" v-loading="loading">
		<div class="top">
			<div class="title">会议主题：{{MeetingDetail.meetingTitle}} <i></i> </div>
			<div class="tabs" v-if="meetStatus == 'UPLOAD'||meetStatus == 'ERROR'||meetStatus == 'SUCCESS'">
				<template v-for="(item,i) in list">
					<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
						{{item.name}}
						<i></i>
					</p>
					<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
				</template>
			</div>
			<div class="tabs" v-else>
				<template v-for="(item,i) in list2">
					<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
						{{item.name}}
						<i></i>
					</p>
					<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
				</template>
			</div>
		</div>
		<el-alert v-if="MeetingDetail.errorMessage" :title="'驳回原因：' + MeetingDetail.errorMessage" type="error"
			style="margin-bottom: 20px;"></el-alert>
		<div class="laborProgress" label="费用明细" v-if="curNow=='1'">
			<div class="lpBox">
				<el-row class="tabTitle">
					<el-col :span="6">
						<span>姓名</span>
					</el-col>
					<el-col :span="6">
						<span>讲者身份</span>
					</el-col>
					<el-col :span="6">
						<span>税前</span>
					</el-col>
					<el-col :span="6">
						<span>税后</span>
					</el-col>
				</el-row>
				<el-row class="tabContent" v-for="(item,i) in orderList.orders" :key="i">
					<el-col :span="6">
						<span>{{item.fullName}}</span>
					</el-col>
					<el-col :span="6">
						<span>{{item.roleName}}</span>
					</el-col>
					<el-col :span="6">
						<span class="preTax">¥{{item.preTax?item.preTax:0}}</span>
					</el-col>
					<el-col :span="6">
						<span class="afterTax">¥{{item.afterTax?item.afterTax:0}}</span>
					</el-col>
				</el-row>
				<el-row class="endNode">
					<el-col :span="6">
						<span>小计：</span>
					</el-col>
					<el-col :span="6" :offset="6">
						<span class="preAmount">¥{{orderList.preAmount?orderList.preAmount:0}}</span>
					</el-col>
					<el-col :span="6">
						<span class="amount">¥{{orderList.amount?orderList.amount:0}}</span>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="conferenceReplay" label="劳务状态" v-if="curNow=='2'">
			<div class="crBox">
				<el-row class="tabTitle">
					<el-col :span="6">
						<span>姓名</span>
					</el-col>
					<el-col :span="6">
						<span>签署情况</span>
					</el-col>
					<el-col :span="6">
						<span>支付进度</span>
					</el-col>
					<el-col :span="6">
						<span>操作</span>
					</el-col>
				</el-row>
				<el-row class="tabContent" v-for="(item,i) in agreementList" :key="i">
					<el-col :span="6">
						<span>{{item.fullName}}</span>
					</el-col>
					<el-col :span="6">
						<el-tag size="mini" v-if="item.status=='WAIT'" type="warning">待签署</el-tag>
						<el-tag size="mini" v-else type="success">已签署</el-tag>
					</el-col>
					<el-col :span="6">
						<el-tag size="mini" v-if="item.orderStatus=='WAIT'" type="warning">待支付</el-tag>
						<el-tag size="mini" v-else-if="item.orderStatus=='ERROR'" type="danger">支付驳回</el-tag>
						<el-tag size="mini" v-else type="success">已支付</el-tag>
					</el-col>
					<el-col :span="6">
						<el-button @click="sendText(item)" size='mini' type="danger"
							v-if="item.isSend=='NO'&&item.status=='WAIT'">
							短信提醒
						</el-button>
						<el-button @click="sendText(item)" size='mini' type="danger"
							v-else-if="item.isSend=='YES'&&item.status=='WAIT'">
							再次提醒
						</el-button>
						<el-button size='mini' type="text" v-else>
							无
						</el-button>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="meetingDetails" label="会议详情" v-if="curNow=='3'">
			<div class="left">
				<el-row :gutter="50">
					<el-col :span="24">
						<p>会议主题</p>
						<div>{{MeetingDetail.meetingTitle}}</div>
					</el-col>
				</el-row>
				<el-row :gutter="50">
					<el-col :span="24">
						<p>所属项目</p>
						<div>{{MeetingDetail.projectName}}</div>
					</el-col>
				</el-row>
				<el-row :gutter="50">
					<el-col :span="12">
						<p>会议日期</p>
						<div>{{$moment(MeetingDetail.meetingStartTime).format('L')}}</div>
					</el-col>
					<el-col :span="12">
						<p>会议开始时间</p>
						<div>{{$moment(MeetingDetail.meetingStartTime).format('LT')}}</div>
					</el-col>
				</el-row>
				<el-row :gutter="50" v-if="MeetingDetail.meetingFormat == 'OFFLINE'">
					<el-col :span="12">
						<p>参会地点</p>
						<div>{{MeetingDetail.location.map(item=>item.trim()).join('/')}}</div>
					</el-col>
					<el-col :span="12">
						<p>详细地址</p>
						<div>{{MeetingDetail.position}}</div>
					</el-col>
				</el-row>
				<el-row :gutter="50" v-if="roleList">
					<el-col>
						<p>参会人员</p>
					</el-col>
				</el-row>
				<el-row :gutter="50" v-if="roleList">
					<el-col :span="24" v-for="(item,i) in MeetingDetail.users" :key="i">
						<p>{{roleList[i]}}</p>
						<div>
							<template v-for="(item2,k) in item">
								{{item2.fullName}}
								<template v-if="k < item.length-1">,</template>
							</template>
						</div>
					</el-col>
				</el-row>

			</div>

			<div class="right">
				<el-row :gutter="50">
					<el-col :span="24">
						<p>直播间背景图</p>
						<div>
							<el-image :src="MeetingDetail.bgImage" fit="cover" :preview-src-list="srcList"></el-image>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="50">
					<el-col :span="24">
						<p>会议主题</p>
						<div>
							<el-image :src="MeetingDetail.bill" fit="cover" :preview-src-list="srcList"></el-image>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="postMeetingUpload" label="会后上传" v-if="curNow=='4'">
			<div class="row" :gutter="50">
				<p>01-会议日程</p>
				<div>
					<el-upload class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
						:on-preview="handlePreview" :on-success="handSuccess1" :on-remove="handleRemove1"
						:file-list="file1" list-type="picture-card">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="row" :gutter="50">
				<p>02-讲者PPT照片（体现会议主题）</p>
				<div>
					<el-upload class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
						:on-preview="handlePreview" :on-success="handSuccess2" :on-remove="handleRemove2"
						:file-list="file2" list-type="picture-card">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="row" :gutter="50">
				<p>03-会议开始照片（体现日期时间）</p>
				<div>
					<el-upload class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
						:on-preview="handlePreview" :on-success="handSuccess3" :on-remove="handleRemove3"
						:file-list="file3" list-type="picture-card">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="row" :gutter="50">
				<p>04-会议结束照片（体现日期时间）</p>
				<div>
					<el-upload class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
						:on-preview="handlePreview" :on-success="handSuccess4" :on-remove="handleRemove4"
						:file-list="file4" list-type="picture-card">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="row" :gutter="50">
				<p>05-会议规模照片（体现所有参会人）</p>
				<div>
					<el-upload class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
						:on-preview="handlePreview" :on-success="handSuccess5" :on-remove="handleRemove5"
						:file-list="file5" list-type="picture-card">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="row" :gutter="50">
				<p>06-其他</p>
				<div>
					<el-upload class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`" multiple
						:on-preview="handlePreview" :on-success="handSuccess6" :on-remove="handleRemove6"
						:file-list="file6" list-type="picture-card">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
		</div>
		<div class="participationLink" label="参会链接" v-if="curNow=='5'">
			<!-- <p>{{MeetingDetail.codeUrl}}</p>
			<p>{{MeetingDetail.inviteCodeUrl}}</p>
			<p>{{MeetingDetail.liveUrl}}</p> -->
			<div class="row">
				<div class="col" v-if="MeetingDetail.meetingFormat == 'OFFLINE'">
					<div class="qrcode">
						<h4>签到二维码（扫码签到）</h4>
						<img :src="MeetingDetail.signCodeUrl" alt="">
					</div>
				</div>
				<div class="col" v-else>
					<div class="qrcode">
						<h4>用户参会二维码（观看直播）</h4>
						<img :src="MeetingDetail.codeUrl" alt="">
					</div>
					<div class="linkUrl">
						<h4>用户参会链接（观看直播）</h4>
						<!-- <a>{{MeetingDetail.liveUrl}}</a> -->
						<a :href="MeetingDetail.liveUrl" target="_blank">{{MeetingDetail.liveUrl}}</a>
					</div>
				</div>
				<div class="col">
					<div class="qrcode">
						<h4>邀请二维码（参与直播）</h4>
						<img :src="MeetingDetail.inviteCodeUrl" alt="">
					</div>
				</div>
			</div>
		</div>

		<div class="options">
			<el-button class="back" @click="back()">返回</el-button>
			<el-button v-if="MeetingDetail.meetingStatus=='WAIT'" type="warning"
				@click="delMeetingFun()">取消会议</el-button>
			<el-button v-if="MeetingDetail.meetingStatus=='WAIT'" type="primary"
				@click="ChangeMeetingDetail()">修改会议信息</el-button>
			<el-button v-if="MeetingDetail.meetingStatus=='WAIT'||MeetingDetail.meetingStatus=='LIVING'" type="primary"
				class="enter" @click.stop="enterTheMeeting()">进入会议</el-button>
			<el-button v-if="(MeetingDetail.meetingStatus=='UPLOAD'||MeetingDetail.meetingStatus=='ERROR')&&curNow==4"
				type="primary" class="back" @click="upMeetingFile()">提交会后文件</el-button>
			<el-button v-if="MeetingDetail.meetingStatus=='LIVING'" type="danger" class="enter"
				@click.stop="closeTheMeeting()">结束会议</el-button>
		</div>

		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>
<script>
	import {
		sendText,
		meetingOver,
		getMeetRoleList,
		getAgreementList,
		getUpdateMeetInfo,
		orderListAssistant,
		getMeetingUrl,
		uploadMeetingFile,
		getMeetingFile,
		delMeetingFile,
		delMeeting
	} from '@/request/api.js';
	import baseUrl from "@/request/config.js"
	export default {
		name: 'meetdetails',
		data() {
			return {
				loading: false,
				baseUrl: '',
				curNow: "1",
				list: [{
					name: '费用明细',
					class: '1',
				}, {
					name: '劳务状态',
					class: '2',
				}, {
					name: '会议详情',
					class: '3',
				}, {
					name: '会后上传',
					class: '4',
				}],
				list2: [{
					name: '费用明细',
					class: '1',
				}, {
					name: '劳务状态',
					class: '2',
				}, {
					name: '会议详情',
					class: '3',
				}, {
					name: '会议链接',
					class: '5',
				}],
				srcList: [],
				meetingId: '',
				activeName: '1',
				MeetingDetail: '',
				users: '',
				agreementList: [],
				orderList: '', // 费用明细
				roleList: '',
				template: '',
				meetStatus: '',
				dialogVisible: false,
				dialogImageUrl: '',
				file1: [],
				file2: [],
				file3: [],
				file4: [],
				file5: [],
				file6: [],
				annotation: {
					file1: '会议日程',
					file2: '讲者PPT照片',
					file3: '会议开始照片',
					file4: '会议结束照片',
					file5: '会议规模照片',
					file6: '其他',
				},
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.meetStatus = this.$route.query.status;
			this.meetingId = this.$route.query.mId;
			this.curNow = this.$route.query.now;
		},
		mounted() {
			this.getMeetInfo();
			this.getMeetFile();
		},
		methods: {
			// 删除会议
			delMeetingFun() {
				this.$confirm(`此操作删除会议【${this.MeetingDetail.meetingTitle}】, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.meetingId
					}
					delMeeting(data).then(res => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});

							let projectId = JSON.parse(localStorage.getItem('projectDetails')).id;
							this.$router.push({
								name: 'meetinglist',
								query: {
									pId: projectId
								}
							})
						}
					})
				})
			},

			// 会后上传文件回显
			getMeetFile() {
				getMeetingFile({
					meetingId: this.meetingId
				}).then(res => {
					for (let key in res.data.values) {
						this[key] = res.data.values[key].map(item => {
							return {
								url: item
							}
						})
					}
				})
			},

			// 上传会后文件
			upMeetingFile() {
				this.loading = true;
				let data = {
					files: {
						file1: this.file1,
						file2: this.file2,
						file3: this.file3,
						file4: this.file4,
						file5: this.file5,
						file6: this.file6
					},
					// joinNums: this.model.peopleNum,
					meetingId: this.meetingId,
					annotation: this.annotation
				};

				for (let i in data.files) {
					data.files[i] = data.files[i].map(item => item.response ? item.response : item.url)
				}

				uploadMeetingFile(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						// console.log(res);
						this.$router.push({
							name: 'meetinglist',
							query: {
								pId: this.MeetingDetail.projectId
							}
						})
						this.$notify({
							title: '上传成功',
							type: 'success',
							message: '上传成功',
							position: 'bottom-right'
						});
					}
				})
			},

			handSuccess1(res, file, fileList) {
				this.file1 = fileList;
			},
			handSuccess2(res, file, fileList) {
				this.file2 = fileList;
			},
			handSuccess3(res, file, fileList) {
				this.file3 = fileList;
			},
			handSuccess4(res, file, fileList) {
				this.file4 = fileList;
			},
			handSuccess5(res, file, fileList) {
				this.file5 = fileList;
			},
			handSuccess6(res, file, fileList) {
				this.file6 = fileList;
			},
			// 删除上传的内容
			deletePic(file) {

				console.log(file)
				delMeetingFile({
					filePath: file.url
				}).then(res => {
					if (res.code == 200) {
						console.log(res.data);
					}
				})
			},
			handleRemove1(file, fileList) {
				this.file1 = fileList;
				this.deletePic(file)
			},
			handleRemove2(file, fileList) {
				this.file2 = fileList;
				this.deletePic(file)
			},
			handleRemove3(file, fileList) {
				this.file3 = fileList;
				this.deletePic(file)
			},
			handleRemove4(file, fileList) {
				this.file4 = fileList;
				this.deletePic(file)
			},
			handleRemove5(file, fileList) {
				this.file5 = fileList;
				this.deletePic(file)
			},
			handleRemove6(file, fileList) {
				this.file6 = fileList;
				this.deletePic(file)
			},

			handlePreview(file) {
				// console.log(file);
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},

			tabsChange(item) {
				// this.page = 1;
				this.curNow = item.class;
				// this.getProjectList();
			},

			// 结束会议
			closeTheMeeting() {
				let data = {
					meetingId: this.meetingId
				}

				this.$confirm('此操作将会结束当前的会议, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					meetingOver(data).then(res => {
						if (res.code == 200) {
							this.$router.push({
								name: 'meetinglist',
								query: {
									pId: this.MeetingDetail.projectId
								}
							})
							this.getMeetInfo();
							this.$message({
								type: 'info',
								message: '会议已结束!'
							});
							this.$notify({
								type: 'error',
								title: '提示',
								message: '会议已结束!',
								position: 'bottom-right'
							});
						}
					})
				});
			},

			// 进入会议
			enterTheMeeting() {
				let data = {
					meetingId: this.meetingId
				}
				getMeetingUrl(data).then(res => {
					if (res.code == 200) {
						window.open(res.data)
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
						this.$notify({
							type: 'error',
							title: '错误',
							message: res.msg,
							position: 'bottom-right'
						});
					}
				})
			},

			// 修改会议信息
			ChangeMeetingDetail() {
				// console.log(JSON.parse(JSON.stringify(this.MeetingDetail)))
				let projectId = JSON.parse(localStorage.getItem('projectDetails')).id;
				this.$router.push({
					name: 'createmeet',
					query: {
						pId: projectId,
						mId: this.meetingId
					}
				})
			},

			// 发送提醒签协议短信
			sendText(item) {
				let data = {
					ids: [item.id]
				};
				sendText(data).then(res => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.$notify({
							type: 'success',
							title: '发送成功',
							message: res.msg,
							position: 'bottom-right'
						});
						this.getAgreementListFun();
					};
				})
			},

			// 获取会议信息
			getMeetInfo() {
				let data = {
					meetingId: this.meetingId
				}
				getUpdateMeetInfo(data).then(res => {
					if (res.code == 200) {
						this.MeetingDetail = res.data
						this.srcList = [];
						this.srcList.push(res.data.bgImage);
						this.srcList.push(res.data.bill);

						getMeetRoleList({
							meetingId: this.MeetingDetail.id
						}).then(res => {
							if (res.code == 200) {
								let roleData = {};
								res.data.map(item => {
									roleData[item.id] = item.roleName
								})
								this.roleList = roleData;
							}
						})
					}
				})


				orderListAssistant(data).then(res => {
					if (res.code == 200) {
						this.orderList = res.data;
					}
				})

				this.getAgreementListFun();

			},

			getAgreementListFun() {
				let data = {
					meetingId: this.meetingId
				}
				getAgreementList(data).then(res => {
					console.log(res)
					if (res.code == 200) {

						this.agreementList = res.data;
					}
				})
			},

			signAnAgreement() {
				this.$router.push({
					name: 'agreement'
				})
			},

			back() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style lang="scss" scoped>
	#meetdetails {
		padding: 20px;
		display: flex;
		flex-direction: column;
		height: 100%;

		.top {
			padding: 20px 20px;
			margin-bottom: 20px;
			border-radius: 20px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
		}

		.title {
			font-size: 20px;
			padding: 0 20px 0 30px;
			position: relative;

			i {
				top: 50%;
				left: 0px;
				width: 20px;
				height: 20px;
				position: absolute;
				border-radius: 50%;
				background-color: #3563E9;
				border: 5px solid #C2D0F8;
				transform: translateY(-50%);
			}
		}

		.tabs {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;

			.el-divider {
				width: 2px;
				height: 30px;
				margin: 0;
			}

			p {
				width: 100%;
				padding: 20px 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				cursor: pointer;
				line-height: 2.5;
				position: relative;

				i {
					width: 100%;
					left: 0;
					bottom: 0;
					height: 0;
					background-color: #0065FF;
					position: absolute;
					transition: all .3s;
				}

				span {
					margin-left: 10px;
					height: 26px;
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					background-color: #007AFF;
					border-radius: 5px;
					color: #fff;
				}
			}

			.active i {
				height: 6px;
			}
		}


		.el-tabs__item {
			font-size: 16px;
		}

		.el-tab-pane {
			overflow: hidden;
		}

		.laborProgress {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.lpBox {
				width: 100%;
				padding: 20px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				border-radius: 20px;
			}

			.el-row {
				padding: 10px 0;
				margin-bottom: 20px;
			}

			.tabTitle {
				border-bottom: 2px solid #eee;
				width: 100%;

				* {
					font-size: 16px;
				}

				.el-col {
					text-align: center;
					border-right: 1px solid #ccc;

					&:last-child {
						border-right: none;
					}
				}
			}

			.tabContent {
				border-radius: 10px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				width: 100%;

				* {
					font-size: 15px;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1F6FC;
					box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
				}

				.afterTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1FCF5;
					box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
				}
			}

			.endNode {
				border-radius: 10px;
				width: 100%;
				margin: 0;

				* {
					font-size: 16px;
					color: #0068FF;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preAmount {
					font-size: 20px;
				}

				.amount {
					font-size: 20px;
				}
			}

		}

		.meetingDetails {
			display: flex;
			flex-direction: row;

			.el-row {
				margin: 10px 0;
			}

			.el-col {
				overflow: hidden;

				>p {
					font-size: 14px;
					color: #112950;
				}

				>div {
					margin: 10px 0;
					padding: 14px 20px;
					border-radius: 10px;
					font-size: 15px;
					color: #000;
					text-align: justify;
				}
			}

			::v-deep .el-image {
				width: 300px;
				height: 200px;
				padding: 10px;
				border-radius: 6px;
				box-shadow: 0 0 20px rgba(0, 0, 0, .1);
			}

			.left {
				flex: 3;
				margin-right: 20px;

				.el-col {
					>div {
						background-color: #F6F7F9;
					}
				}
			}

			.right {
				flex: 2;

				.el-col {
					>div {
						padding: 0;
					}
				}
			}
		}

		.participationLink {
			.row {
				display: flex;

				.col {
					flex: 1;
				}
			}

			.qrcode,
			.linkUrl {
				margin-bottom: 30px;

				h4 {
					font-size: 22px;
					margin-bottom: 10px;
				}

				img {
					display: block;
					width: 300px;
					height: 300px;
					border-radius: 20rpx;
					box-shadow: 0 0 20px rgba(0, 0, 0, .1);
					overflow: hidden;
				}

				span {
					font-size: 14px;
				}
			}

			.options {
				display: flex;
				justify-content: center;

				.el-button {
					width: 200px;
				}
			}
		}

		.postMeetingUpload {
			padding: 20px;
			display: flex;
			flex-wrap: wrap;

			.row {
				margin-bottom: 30px;
				width: 50%;
				padding-right: 50px;

				p {
					margin-bottom: 20px;
					color: #112950;
				}

				::v-deep .upload {
					padding: 0;

					.el-upload--picture-card {
						// display: flex;
						// align-items: center;
						// justify-content: center;
						width: 130px;
						height: 130px;
					}

					.el-upload-list__item {
						width: 130px;
						height: 130px;
					}
				}
			}
		}

		.conferenceReplay {
			.crBox {
				width: 100%;
				padding: 20px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				border-radius: 20px;
			}

			.el-row {
				padding: 10px 0;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
			}

			.tabTitle {
				border-bottom: 2px solid #eee;
				width: 100%;

				* {
					font-size: 16px;
				}

				.el-col {
					text-align: center;
					border-right: 1px solid #ccc;

					&:last-child {
						border-right: none;
					}
				}
			}

			.tabContent {
				border-radius: 10px;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				width: 100%;

				* {
					font-size: 15px;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1F6FC;
					box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
				}

				.afterTax {
					padding: 5px 20px;
					border-radius: 20px;
					background-color: #F1FCF5;
					box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
				}
			}

			.endNode {
				border-radius: 10px;
				width: 100%;
				margin: 0;

				* {
					font-size: 16px;
					color: #0068FF;
				}

				.el-col {
					padding: 10px 20px;
					text-align: center;
				}

				.preAmount {
					font-size: 20px;
				}

				.amount {
					font-size: 20px;
				}
			}
		}

		.options {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;


			.el-button {
				width: 280px;
				height: 50px;
				margin: 0;
				margin: 20px;
			}

			.el-link {
				.el-link--inner {
					display: flex;
					align-items: center;
					font-size: 16px;
				}

				.el-icon-check {
					border-radius: 5px;
					border: 1px solid #67C23A;
					margin-right: 5px;
				}
			}
		}
	}
</style>